import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from 'utils/axios';
import { IdeaFilter, IdeaPagination, IdeaState, IdeaStatuses } from '../../@types/idea/idea';
import { AxiosResponse } from 'axios';

// ----------------------------------------------------------------------

const initialState: IdeaState = {
  isLoading: false,
  error: false,
  ideas: {
    items: [],
    links: {},
    meta: {
      currentPage: 1,
      itemCount: 0,
      itemsPerPage: 10,
      totalItems: 0,
      totalPages: 1
    }
  },
  sortBy: null,
  filters: {
    focus_area: [],
    rating: null,
    start_date: null,
    end_date: null
  },
  questions: [],
  statistics: {
    idea: 0,
    idea_contributor: 0
  },
  weeklyStatistics: {
    idea: Array(7).fill(0),
    idea_contributor: Array(7).fill(0)
  },
  ideaCounts: {
    accepted_idea_count: 0,
    waiting_idea_count: 0,
    rejected_idea_count: 0,
    snoozed_idea_count: 0,
    idea_contributor_count: 0
  },
  ideaAnalytics: {
    jury_assignment_waiting: 0,
    on_jury_assessment: 0,
    jury_assessment_completed: 0,
    approved: 0
  },
  focusAreas: [
    {
      value: 'Bağımsız ve Talep Üzerine Çalışma Ekosistemi',
      label: 'Bağımsız ve Talep Üzerine Çalışma Ekosistemi'
    },
    { value: 'Alternatif Sahip Olma Modelleri', label: 'Alternatif Sahip Olma Modelleri' },
    {
      value: 'Karbon Ayak İzi Takibi/Sürdürülebilirlik',
      label: 'Karbon Ayak İzi Takibi/Sürdürülebilirlik'
    },
    { value: 'Sosyal Etki', label: 'Sosyal Etki' },
    { value: 'Lojistik', label: 'Lojistik' },
    { value: 'Metaverse ve NFT', label: 'Metaverse ve NFT' }
  ],
  focusAreaStrings: [
    'Bağımsız ve Talep Üzerine Çalışma Ekosistemi',
    'Alternatif Sahip Olma Modelleri',
    'Karbon Ayak İzi Takibi/Sürdürülebilirlik',
    'Sosyal Etki',
    'Lojistik',
    'Metaverse ve NFT'
  ],
  jury: []
};

const slice = createSlice({
  name: 'idea',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getIdeasSuccess(state, action) {
      state.isLoading = false;
      state.ideas = action.payload;
    },

    getQuestionsSuccess(state, action) {
      state.isLoading = false;
      state.questions = action.payload;
    },

    getJurySuccess(state, action) {
      state.isLoading = false;
      state.jury = action.payload;
    },

    getStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.statistics = action.payload;
    },

    getWeeklyStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.weeklyStatistics = action.payload;
    },

    getIdeaCountsSuccess(state, action) {
      state.isLoading = false;
      state.ideaCounts = action.payload;
    },

    getIdeaAnalyticsSuccess(state, action) {
      state.isLoading = false;
      state.ideaAnalytics = action.payload;
    },

    sortByIdeas(state, action) {
      state.sortBy = action.payload;
    },

    filterIdeas(state, action) {
      state.filters.focus_area = action.payload.focus_area;
      state.filters.rating = action.payload.rating;
      state.filters.start_date = action.payload.start_date;
      state.filters.end_date = action.payload.end_date;
    }
  }
});
export const { sortByIdeas, filterIdeas } = slice.actions;
// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// FOR: IDEAS
export function getIdeas(
  ideaStatus?: IdeaStatuses,
  ideaFilter?: IdeaFilter,
  ideaPagination?: IdeaPagination
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const query = `idea-status=${ideaStatus || 'CREATED'}&limit=${
        ideaPagination?.limit || 10
      }&page=${ideaPagination?.page || 1}`;
      const response: AxiosResponse = await axios.post(`idea/get-ideas?${query}`, ideaFilter);
      console.log('response data', response.data);
      dispatch(slice.actions.getIdeasSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAssignedIdeasForJury(ideaFilter?: IdeaFilter, ideaPagination?: IdeaPagination) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const query = `limit=${ideaPagination?.limit || 10}&page=${ideaPagination?.page || 1}`;
      const response = await axios.post(`idea/jury/get-ideas?${query}`, ideaFilter);
      dispatch(slice.actions.getIdeasSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getEvaluatedIdeasForJury(ideaFilter?: IdeaFilter, ideaPagination?: IdeaPagination) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const query = `limit=${ideaPagination?.limit || 10}&page=${ideaPagination?.page || 1}`;
      const response = await axios.post(`idea/jury/get-ideas-evaluated?${query}`, ideaFilter);
      dispatch(slice.actions.getIdeasSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getQuestions() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/question`);
      dispatch(slice.actions.getQuestionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getJury() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/user/juries`);
      dispatch(slice.actions.getJurySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getStatistics() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/idea/analytics/total');
      dispatch(slice.actions.getStatisticsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getWeeklyStatistics() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/idea/analytics/weekly');
      dispatch(slice.actions.getWeeklyStatisticsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getIdeaCounts() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/idea/analytics/idea-counts');
      dispatch(slice.actions.getIdeaCountsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getIdeaAnalytics() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/idea/analytics/idea-analytics');
      dispatch(slice.actions.getIdeaAnalyticsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

/*export function getUserIdeas() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/user/ideas');
      dispatch(slice.actions.getIdeaAnalyticsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}*/

// ----------------------------------------------------------------------
