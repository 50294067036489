import Page from 'core/components/Page';
import { styled } from '@mui/material/styles';

const RootStyle = styled(Page)(({ theme }) => ({
  minHeight: '600px'
}));

export function UserManuelPage() {
  return (
    <RootStyle title="Kullanım Kılavuzu">
      <iframe
        allowFullScreen
        frameBorder="0"
        title="page"
        src="kullanim-kilavuzu.pdf"
        height={'100%'}
        width={'100%'}
        style={{
          position: 'absolute'
        }}
      />
    </RootStyle>
  );
}
