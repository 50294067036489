import NProgress from 'nprogress';
import { useEffect, useMemo } from 'react';
import { motion } from 'framer-motion';
// material
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Box, GlobalStyles } from '@mui/material';
//
import Logo from '../../components/Logo';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export function ProgressBarStyle() {
  const theme = useTheme();

  return (
    <GlobalStyles
      styles={{
        '#nprogress': {
          pointerEvents: 'none',
          '& .bar': {
            top: 0,
            left: 0,
            height: 2,
            width: '100%',
            position: 'fixed',
            zIndex: theme.zIndex.snackbar,
            backgroundColor: theme.palette.primary.main,
            boxShadow: `0 0 2px ${theme.palette.primary.main}`
          },
          '& .peg': {
            right: 0,
            opacity: 1,
            width: 100,
            height: '100%',
            display: 'block',
            position: 'absolute',
            transform: 'rotate(3deg) translate(0px, -4px)',
            boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`
          }
        }
      }}
    />
  );
}

function ProgressBar() {
  NProgress.configure({
    showSpinner: false
  });

  useMemo(() => {
    NProgress.start();
  }, []);

  useEffect(() => {
    NProgress.done();
  }, []);

  return null;
}

export default function LoadingScreen({ ...other }) {
  return (
    <>
      <ProgressBar />

      <RootStyle {...other}>
        <motion.div
          initial={{ rotateY: 0 }}
          animate={{ rotateY: 360 }}
          transition={{
            duration: 2,
            ease: 'easeInOut',
            repeatDelay: 1,
            repeat: Infinity
          }}
        >
          {/* <Logo sx={{ width: 64, height: 64 }} /> */}
          <svg
            width="98"
            height="89"
            viewBox="0 0 98 89"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_100_3363)">
              <path
                d="M67.2244 10.1479C67.2244 10.1479 70.2316 6.9214 72.2363 8.97462C72.2363 8.97462 73.4389 4.7213 75.844 5.74773C78.2492 6.77415 78.6501 7.94783 78.6501 7.94783C78.6501 7.94783 75.6429 -1.87901 82.2579 0.32109C88.8728 2.52119 86.2665 7.36135 86.2665 7.36135C86.2665 7.36135 88.4716 2.3743 90.8772 4.57441C93.2827 6.77451 91.4785 7.36135 91.4785 7.36135C91.4785 7.36135 97.6929 5.6012 97.4914 7.50788L95.4871 8.97462L67.2244 10.1479Z"
                fill="#F0EEF1"
              />
              <path
                d="M31.8069 76.8276C31.8295 75.5524 37.4606 73.52 41.6066 71.7732C43.0074 73.7645 44.7672 74.7401 46.437 75.1733C40.2207 76.6918 31.7825 78.1939 31.8069 76.8276Z"
                fill="#5191CD"
              />
              <path
                d="M45.9267 69.303C46.3891 67.5828 46.7346 65.5037 47.4616 64.9176C48.1886 64.3315 58.0798 62.7427 58.0798 62.7427C57.7547 64.4816 55.453 71.9856 52.5202 73.3655C51.6066 73.7954 49.2491 74.4859 46.4369 75.1732C44.7671 74.7412 43.0073 73.7645 41.6064 71.7732C43.9136 70.8015 45.7616 69.918 45.9267 69.303Z"
                fill="#6BB1E2"
              />
              <path
                d="M55.31 66.958C55.6989 67.9877 52.0171 73.914 51.6611 71.5325C51.305 69.1509 54.3542 64.4256 55.31 66.958Z"
                fill="white"
              />
              <path
                d="M15.5424 52.6427C16.741 53.1162 20.8196 48.7754 24.0564 45.6763C22.7412 43.6288 22.5127 41.6483 22.7558 39.9595C18.9294 45.0326 14.2578 52.1355 15.5424 52.6427Z"
                fill="#5191CD"
              />
              <path
                d="M28.0354 42.6988C29.821 42.9444 31.8935 43.4354 32.7222 43.0005C33.5509 42.5656 38.8663 34.1731 38.8663 34.1731C37.1171 33.7954 29.2237 32.985 26.7992 35.12C26.0441 35.7853 24.4862 37.6646 22.7541 39.9594C22.5111 41.6483 22.7396 43.6288 24.0548 45.6762C25.8572 43.9517 27.3976 42.6114 28.0354 42.6988Z"
                fill="#6BB1E2"
              />
              <path
                d="M33.8599 35.0622C33.0494 34.3091 26.093 35.3657 28.1771 36.6129C30.2613 37.86 35.8526 36.916 33.8599 35.0622Z"
                fill="white"
              />
              <path
                d="M89.5957 37.2549C93.9323 29.5838 96.5197 21.7846 94.46 18.4551L94.3624 18.3057C92.1321 15.0918 83.8499 14.4042 74.9864 15.3719C74.5943 18.9556 74.8629 24.1334 78.2604 29.5784C80.7005 33.4886 85.1756 35.84 89.5957 37.2549Z"
                fill="#6BB1E2"
              />
              <path
                d="M78.2604 29.5785C74.8629 24.1335 74.5943 18.9556 74.9864 15.3719C74.5907 15.4152 74.1942 15.4614 73.797 15.5106C73.2952 19.0996 73.3597 24.6062 76.9838 30.4155C79.5483 34.5248 84.3601 36.9129 88.9937 38.3C89.198 37.9515 89.3987 37.6026 89.5958 37.2531C85.1757 35.84 80.7006 33.4887 78.2604 29.5785Z"
                fill="white"
              />
              <path
                d="M60.6743 31.4181C64.4949 29.5251 69.771 27.9925 75.2307 27.0205C73.3591 22.505 73.3955 18.3903 73.7964 15.5105C66.9784 16.363 59.9626 18.1632 55.2039 20.5209C43.5902 26.2756 29.7327 43.7785 29.7327 43.7785L36.2194 53.4271C39.6234 49.3261 50.9362 36.2432 60.6743 31.4181Z"
                fill="#0069B2"
              />
              <path
                d="M77.1737 53.1891C81.1944 49.789 85.5649 44.1439 88.9937 38.3021C84.3601 36.9139 79.5483 34.5258 76.9842 30.4176C76.3067 29.335 75.7202 28.1992 75.2306 27.0219C69.7708 27.9939 64.4947 29.5265 60.6741 31.4195C50.9361 36.2439 39.6233 49.3275 36.2214 53.4289L45.5998 67.374C45.5998 67.374 67.3033 61.5359 77.1737 53.1891Z"
                fill="#1183C6"
              />
              <path
                d="M45.6825 67.3724C46.6322 66.7491 43.8218 60.9201 39.4053 54.353C34.9888 47.7858 30.6387 42.9675 29.689 43.5908C28.7393 44.2142 31.5497 50.0432 35.9662 56.6103C40.3827 63.1774 44.7328 67.9958 45.6825 67.3724Z"
                fill="#010101"
              />
              <path
                d="M33.9028 45.979C32.5059 46.2155 20.7872 49.8269 16.7505 55.605C13.7229 59.9386 13.6489 68.0722 13.8013 71.8683L14.2513 72.1081C17.8955 73.7566 25.1352 76.5702 30.1525 75.5337C37.1086 74.0965 45.0209 64.823 45.7818 63.6425L33.9028 45.979Z"
                fill="#EE7AA5"
              />
              <path
                d="M36.7205 48.5007C35.7858 48.6591 27.943 51.0756 25.2412 54.9426C23.215 57.843 23.164 63.2862 23.2675 65.8265L23.5689 65.9871C26.0083 67.0902 30.8526 68.9731 34.21 68.2793C38.8651 67.3177 44.1587 61.1116 44.6689 60.3228L36.7205 48.5007Z"
                fill="#ED4B80"
              />
              <path
                d="M40.5094 50.4209C40.0583 50.4972 36.2727 51.6637 34.9702 53.5301C33.9921 54.9302 33.9684 57.5576 34.0176 58.7839L34.1634 58.8613C35.3408 59.3938 37.679 60.3014 39.2999 59.9676C41.5469 59.5036 44.1023 56.5078 44.3486 56.1265L40.5094 50.4209Z"
                fill="#EC3167"
              />
              <path
                d="M63.8429 51.4737C70.9137 51.4737 76.6458 45.8108 76.6458 38.8254C76.6458 31.8399 70.9137 26.177 63.8429 26.177C56.7721 26.177 51.04 31.8399 51.04 38.8254C51.04 45.8108 56.7721 51.4737 63.8429 51.4737Z"
                fill="#0069B2"
              />
              <path
                d="M71.8613 46.747C76.2898 42.3721 76.2898 35.2788 71.8613 30.9038C67.4329 26.5288 60.253 26.5288 55.8245 30.9038C51.3961 35.2788 51.3961 42.3721 55.8245 46.7471C60.253 51.122 67.4329 51.122 71.8613 46.747Z"
                fill="#6BB1E2"
              />
              <path
                d="M69.0156 37.6935C69.7645 38.8039 70.1625 40.1091 70.1591 41.4435C70.5967 40.4154 70.77 39.2963 70.6636 38.186C70.5571 37.0757 70.1743 36.0087 69.5491 35.0801C67.4555 31.9669 63.2034 31.1198 60.0522 33.1881C59.1117 33.8049 58.3402 34.642 57.8066 35.6247C57.2729 36.6075 56.9937 37.7054 56.9937 38.8207C57.5139 37.5893 58.3928 36.5385 59.5187 35.8015C62.6699 33.7332 66.922 34.58 69.0156 37.6935Z"
                fill="#5191CD"
              />
              <path
                d="M67.6341 44.4626C68.76 43.7256 69.6389 42.6748 70.1592 41.4434C70.1626 40.1091 69.7645 38.8039 69.0156 37.6934C66.922 34.5799 62.6699 33.7332 59.5188 35.8015C58.3928 36.5384 57.5139 37.5893 56.9937 38.8207C56.9903 40.155 57.3883 41.4602 58.1372 42.5707C60.2308 45.6838 64.4829 46.5309 67.6341 44.4626Z"
                fill="white"
              />
              <path
                d="M78.2775 17.3419L77.815 16.6547L76.9812 16.7065L76.6099 17.4456L77.0723 18.1333L77.9061 18.0814L78.2775 17.3419Z"
                fill="#0069B2"
              />
              <path
                d="M78.4606 21.4942L77.9981 20.8065L77.1643 20.8584L76.793 21.5979L77.2551 22.2851L78.0889 22.2333L78.4606 21.4942Z"
                fill="#0069B2"
              />
              <path
                d="M80.2233 25.5685L79.7608 24.8812L78.927 24.9327L78.5557 25.6722L79.0178 26.3595L79.8516 26.3077L80.2233 25.5685Z"
                fill="#0069B2"
              />
              <path
                d="M83.06 29.4675L82.5975 28.7799L81.7637 28.8318L81.3923 29.5712L81.8544 30.2585L82.6882 30.2067L83.06 29.4675Z"
                fill="#0069B2"
              />
              <path
                d="M86.5799 32.7117L86.1175 32.0244L85.2837 32.0763L84.9124 32.8154L85.3748 33.503L86.2086 33.4508L86.5799 32.7117Z"
                fill="#0069B2"
              />
              <path
                d="M89.9056 34.5464L89.4432 33.8591L88.6094 33.911L88.238 34.6501L88.7005 35.3374L89.5343 35.2859L89.9056 34.5464Z"
                fill="#0069B2"
              />
              <path
                d="M30.9689 60.7069C30.2583 60.0822 30.3257 59.1901 30.641 58.3667C26.3043 61.4384 23.4192 64.3118 23.9673 65.1265C24.5263 65.9574 28.4621 64.3669 33.1267 61.5057C32.3461 61.4409 31.5568 61.2231 30.9689 60.7069Z"
                fill="#5191CD"
              />
              <path
                d="M30.9689 60.7068C31.5567 61.2231 32.3461 61.4409 33.1252 61.506C33.6866 61.1616 34.2576 60.7998 34.838 60.4206C40.2154 56.8923 44.0677 53.2752 43.442 52.3449C42.8163 51.4146 37.9498 53.5215 32.572 57.0515C31.9029 57.4903 31.2575 57.931 30.6406 58.3674C30.3257 59.19 30.2583 60.0821 30.9689 60.7068Z"
                fill="#6BB1E2"
              />
              <path
                d="M39.7907 54.5969C37.8283 53.3872 31.866 58.4725 33.3627 59.1429C34.9413 59.8496 40.7327 55.1772 39.7907 54.5969Z"
                fill="white"
              />
              <path
                d="M12.874 66.5002C15.091 64.31 15.0909 60.7589 12.874 58.5686C10.657 56.3784 7.06251 56.3784 4.84552 58.5686C2.62853 60.7589 2.62852 64.31 4.84552 66.5002C7.06251 68.6904 10.657 68.6904 12.874 66.5002Z"
                fill="#ED4B80"
              />
              <path
                d="M27.8374 75.0744C28.6652 74.8625 29.1624 74.0276 28.9478 73.2098C28.7333 72.392 27.8882 71.9008 27.0604 72.1128C26.2326 72.3248 25.7355 73.1596 25.95 73.9774C26.1646 74.7952 27.0096 75.2864 27.8374 75.0744Z"
                fill="#ED4B80"
              />
              <path
                d="M21.8661 87.8791C23.3777 86.3858 23.3777 83.9647 21.8661 82.4714C20.3546 80.9782 17.9039 80.9782 16.3924 82.4714C14.8809 83.9647 14.8809 86.3858 16.3924 87.8791C17.9039 89.3724 20.3546 89.3724 21.8661 87.8791Z"
                fill="#ED4B80"
              />
              <path
                d="M4.46477 80.0555C5.37175 79.1595 5.37175 77.7067 4.46477 76.8107C3.55779 75.9147 2.08729 75.9147 1.18031 76.8107C0.273336 77.7067 0.273336 79.1595 1.18031 80.0555C2.08729 80.9515 3.55779 80.9515 4.46477 80.0555Z"
                fill="#ED4B80"
              />
              <path
                d="M5.67385 50.3144C5.84288 49.4862 5.30033 48.6795 4.46203 48.5125C3.62373 48.3455 2.80712 48.8815 2.63809 49.7097C2.46906 50.5379 3.01161 51.3446 3.84991 51.5116C4.68821 51.6786 5.50482 51.1426 5.67385 50.3144Z"
                fill="#ED4B80"
              />
              <path
                d="M38.1653 85.8432C38.2928 85.7596 38.4426 85.7152 38.5957 85.7156C38.7487 85.716 38.8982 85.7613 39.0253 85.8456C39.1523 85.93 39.2512 86.0497 39.3094 86.1896C39.3676 86.3294 39.3825 86.4832 39.3522 86.6315C39.322 86.7797 39.2479 86.9158 39.1394 87.0224C39.0308 87.1291 38.8927 87.2015 38.7425 87.2307C38.5923 87.2598 38.4367 87.2442 38.2954 87.186C38.1542 87.1278 38.0335 87.0295 37.9488 86.9035C37.8353 86.7345 37.7943 86.5279 37.8349 86.3291C37.8755 86.1303 37.9943 85.9555 38.1653 85.8432V85.8432Z"
                fill="#ED4B80"
              />
              <path
                d="M54.7236 11.5413C54.9058 11.3376 55.1974 10.6413 55.5221 10.5606C55.8468 10.48 56.3264 10.3806 56.3264 10.3806C56.3264 10.3806 55.9783 10.8227 55.8847 10.8375C55.791 10.8522 56.4761 10.9325 56.7498 11.0272C57.0235 11.1219 57.2669 11.3455 57.2669 11.3455C57.0975 11.5177 56.8816 11.6383 56.6449 11.6929C56.26 11.7761 55.0013 12.0652 55.0013 12.0652L54.7236 11.5413Z"
                fill="#FAC5C9"
              />
              <path
                d="M42.5591 22.5199C43.2941 22.7673 48.591 19.4784 48.9791 19.0882C49.3672 18.6979 55.3565 12.1419 55.3565 12.1419L54.644 11.2058C54.644 11.2058 48.7215 14.9141 47.9103 15.3184C47.099 15.7227 44.2183 17.499 43.6002 18.5373C42.9822 19.5756 41.6582 22.2171 42.5591 22.5199Z"
                fill="#234288"
              />
              <path
                d="M48.8359 44.1292L49.0837 46.3311L50.1464 45.7104L50.4317 43.4229L48.8359 44.1292Z"
                fill="#FAC5C9"
              />
              <path
                d="M50.9858 45.5485C50.9858 45.5485 54.5764 45.097 54.4751 46.3077C54.3738 47.5185 48.8437 47.8144 48.8914 46.6847C48.9392 45.5549 49.3684 45.9481 49.5441 45.772C49.7198 45.596 50.9858 45.5485 50.9858 45.5485Z"
                fill="#ED7395"
              />
              <path
                d="M45.0296 10.9476C45.1648 10.7028 45.378 10.6236 45.6265 10.4151C45.7107 10.3431 45.918 11.7299 45.9953 11.6601C46.0339 11.6241 45.9844 10.5465 46.0197 10.5112C46.2165 10.3161 46.3517 10.1152 46.3218 9.88587C46.2435 9.28967 45.4753 8.30681 44.5937 8.79896C43.7122 9.29111 43.6721 9.22055 42.9232 9.16474C42.1743 9.10894 41.0924 10.0612 41.5749 11.0102C42.0573 11.9592 41.7268 11.7782 41.7173 12.4863C41.7079 13.1945 43.3208 14.3322 43.4086 13.8407C43.4965 13.3493 45.2107 13.4659 44.7625 13.211C44.1794 12.8802 43.2629 11.5549 43.3984 11.6122C43.534 11.6694 44.699 11.5459 45.0296 10.9476Z"
                fill="#ED7395"
              />
              <path
                d="M34.3556 35.3338C37.7859 38.4966 46.5531 32.9108 47.0852 34.0161C47.6173 35.1213 47.6231 45.022 48.2185 45.2917C48.814 45.5613 51.0381 43.965 50.9601 43.5185C50.8821 43.0721 51.2731 33.521 51.0486 32.8111C49.5243 27.9918 48.9146 30.1001 41.6437 28.0469C41.6437 28.0469 35.9419 28.3547 34.0127 30.8155C33.1359 31.9341 31.387 32.5958 34.3556 35.3338Z"
                fill="#E44285"
              />
              <path
                d="M34.7489 16.7123C35.5175 15.4313 35.6964 14.8521 36.7219 14.1709C38.3411 13.0959 38.137 13.7083 38.4886 13.9106C38.6563 14.0071 38.9045 14.2595 39.1785 14.4812L39.9613 14.8863C39.1792 14.8297 37.6683 16.8084 37.7667 17.607C37.8032 17.9097 37.1589 17.1793 37.2456 18.1646C36.7719 19.4355 36.2617 20.845 35.8608 22.0392C35.5693 22.4352 35.3703 22.6873 35.3291 22.6898C35.1469 22.6963 34.2358 22.5497 34.3237 22.9418C34.4115 23.3339 34.2256 23.7338 35.0066 23.777C35.105 23.7825 35.2205 23.7479 35.3473 23.6831C35.3171 23.7911 35.2887 23.8969 35.265 23.9923C35.187 24.303 35.1017 24.8049 35.0219 25.3378C34.2716 25.1693 32.5697 25.9858 32.5938 23.9985C32.831 23.9815 32.8146 23.8015 32.8146 23.8015C32.8146 23.8015 32.9097 21.9539 33.1112 21.5172C33.3128 21.0805 33.691 19.491 33.691 19.491L33.6181 19.4845C33.7891 18.9084 33.9614 18.3634 34.1283 17.8665C34.2537 17.7823 34.3131 17.4388 34.7489 16.7123Z"
                fill="#181335"
              />
              <path
                d="M41.541 15.0425C41.728 15.2499 42.4692 16.1359 42.7462 16.4535C43.0231 16.771 43.2655 17.234 43.6284 17.0115C43.3854 17.256 43.866 17.3434 44.0227 17.2855C44.1627 17.2336 44.7308 16.5867 44.9567 16.173C44.8376 17.221 44.5716 20.089 44.5129 20.5347C44.4422 21.0747 44.2268 22.1188 44.2268 22.1188C44.2268 22.1188 44.2837 22.068 44.3587 21.9939C44.3325 22.0726 44.3058 22.1533 44.2786 22.2362C44.1565 22.1004 43.2472 25.633 43.2057 25.7417C42.6828 27.1116 40.2411 26.5734 39.2477 26.6418C39.8308 26.5514 34.9694 24.5579 34.9694 24.5579C34.9002 24.3751 36.7463 16.2882 39.2175 15.0861C39.4656 14.6335 40.2535 16.9139 40.3472 16.6367C40.5509 16.0315 40.6139 15.2956 40.5316 14.7077C40.6041 14.6181 40.9106 14.3426 41.541 15.0425Z"
                fill="#2968B2"
              />
              <path
                d="M44.3828 15.5768C44.3828 15.5768 44.4025 16.1002 44.2126 16.4657C44.2797 16.2136 44.3172 15.8788 44.3817 15.576L44.3828 15.5768Z"
                fill="#2968B2"
              />
              <path
                d="M43.5891 16.5808L43.7815 16.8328C43.8035 16.8419 43.8263 16.8488 43.8497 16.8533C43.7939 16.8814 43.7655 16.8739 43.6285 17.0118C43.6729 16.984 43.6558 16.817 43.5891 16.5808Z"
                fill="#2968B2"
              />
              <path
                d="M40.5246 22.4038C40.3654 22.3909 40.2619 22.3628 40.2437 22.3138C40.3325 22.3568 40.4271 22.3871 40.5246 22.4038V22.4038Z"
                fill="#2968B2"
              />
              <path
                d="M35.8621 22.0392C36.2629 20.845 36.7731 19.4356 37.2469 18.1647C37.2931 18.6878 37.346 19.2631 37.405 19.8489C36.8391 20.665 36.2644 21.4899 35.8621 22.0392Z"
                fill="#2968B2"
              />
              <path
                d="M43.1633 10.0024C43.1765 9.98329 43.1904 9.96629 43.2039 9.94641V9.94822C43.1897 9.96629 43.1758 9.98401 43.1633 10.0024Z"
                fill="#FAC5C9"
              />
              <path
                d="M42.9663 10.3597C42.9936 10.2981 43.0228 10.238 43.0545 10.1797C43.052 10.1901 43.0505 10.2009 43.0479 10.2114C43.0205 10.2579 42.9933 10.3074 42.9663 10.3597Z"
                fill="#FAC5C9"
              />
              <path
                d="M37.5726 21.3912C37.0752 22.0623 36.0399 23.327 35.3471 23.6817C35.2203 23.7465 35.1048 23.781 35.0064 23.7756C34.2254 23.7317 34.4102 23.3314 34.3234 22.9404C34.2367 22.5494 35.1481 22.6963 35.3289 22.6884C35.3701 22.6884 35.5709 22.4331 35.8606 22.0378C36.2614 21.4884 36.8376 20.6636 37.4021 19.8474C37.4556 20.3666 37.5125 20.8908 37.5726 21.3912Z"
                fill="#2B294A"
              />
              <path
                d="M44.4323 21.9211C44.4068 21.9478 44.3821 21.973 44.3595 21.9957C44.2844 22.0698 44.2275 22.1206 44.2275 22.1206C44.2275 22.1206 44.4429 21.0744 44.5136 20.5365C44.5723 20.0908 44.8383 17.2243 44.9575 16.1748C44.9833 16.1292 45.0058 16.082 45.0249 16.0333C45.053 15.9473 45.0594 15.8559 45.0435 15.7669C45.1462 15.7474 45.4327 15.9771 45.6834 16.3584C45.6834 16.3584 45.6801 16.4715 45.6681 16.6691C45.6189 17.4586 45.4258 19.6213 44.714 21.55C44.674 21.6544 44.5471 21.8023 44.4323 21.9211Z"
                fill="#2B294A"
              />
              <path
                d="M36.0892 13.2265C37.193 12.1198 39.5512 12.6785 40.6466 13.2769C39.6044 14.2363 40.5121 14.7332 40.5121 14.7332C40.5121 14.7332 40.5187 14.7231 40.5307 14.7083C40.6131 15.2962 40.6532 16.39 39.5293 18.9472C39.4116 19.2151 37.4229 21.8274 36.7619 22.3804C36.7309 22.0132 36.9944 20.8068 37.3672 19.8851C38.1882 17.8564 39.5493 15.6199 39.5369 15.5313C39.5005 15.2725 39.4881 15.037 39.3741 14.893C39.3631 14.7875 39.3525 14.691 39.3427 14.6075C39.287 14.5676 39.2334 14.5251 39.1784 14.4811C38.9043 14.2594 38.6561 14.007 38.4885 13.9105C38.1368 13.7082 38.3409 13.0958 36.7218 14.1708C35.6956 14.852 35.5174 15.4309 34.7488 16.7122C34.3115 17.4384 34.2536 17.7822 34.1256 17.8643C34.028 17.9277 33.8906 17.8391 33.5149 17.6508C32.6468 17.217 34.8479 14.4703 36.0892 13.2265Z"
                fill="#2B294A"
              />
              <path
                d="M33.1127 21.5157C32.9111 21.9524 32.816 23.8001 32.816 23.8001C32.816 23.8001 32.8324 23.9801 32.5952 23.997C32.4811 23.9997 32.3674 23.9823 32.2595 23.9455C31.6732 23.7695 32.1867 21.9784 32.4297 20.6805C32.652 19.4899 33.3127 19.4593 33.617 19.483L33.6899 19.4895C33.6899 19.4895 33.3142 21.0787 33.1127 21.5157Z"
                fill="#2B294A"
              />
              <path
                d="M42.7715 14.9856C43.1757 15.4796 43.4672 16.1481 43.5889 16.5809C43.6556 16.8171 43.6728 16.9841 43.6283 17.0129C43.2639 17.2354 43.0226 16.7724 42.746 16.4549C42.4694 16.1373 41.7278 15.2513 41.5409 15.0439C40.9104 14.3455 40.604 14.6195 40.5307 14.7098C40.5187 14.7246 40.5121 14.7347 40.5121 14.7347C40.5121 14.7347 39.6044 14.2378 40.6466 13.2784C40.675 13.2521 40.6991 13.2269 40.7308 13.1999C41.9133 12.1882 42.6433 13.2888 42.6433 13.2888L42.3882 13.9135C42.3598 13.96 42.3446 14.0132 42.3441 14.0676C42.3328 14.2274 42.3933 14.5226 42.7715 14.9856Z"
                fill="#234288"
              />
              <path
                d="M43.1737 17.3744C42.9262 17.2632 42.7363 17.0209 42.6088 16.8585C42.5629 16.8006 42.5173 16.7419 42.4703 16.6882C42.3446 16.5442 42.1234 16.2832 41.9015 16.0211C41.6391 15.7115 41.3679 15.3914 41.2692 15.282C40.9558 14.9345 40.8067 14.9389 40.8053 14.9392L40.6304 15.2586L40.3359 15.048C40.2867 15.021 39.8527 14.7697 39.7893 14.2599C39.7386 13.852 39.9438 13.4318 40.3986 13.0138L40.4234 12.9904C40.4452 12.9692 40.4671 12.9487 40.4926 12.9271C40.9572 12.531 41.4288 12.36 41.8938 12.423C42.5498 12.5127 42.9091 13.03 42.9477 13.0891L43.0519 13.2464L42.7079 14.0907C42.7054 14.1288 42.7112 14.3383 43.0559 14.7596C43.5045 15.3079 43.8129 16.0315 43.9408 16.4845C44.0045 16.7105 44.123 17.1325 43.8212 17.3168C43.5745 17.4677 43.3581 17.4572 43.1737 17.3744ZM41.1828 14.3067C41.3862 14.3978 41.5946 14.5634 41.8129 14.8053C41.9189 14.923 42.1817 15.233 42.4598 15.5613C42.6784 15.8202 42.8971 16.078 43.0221 16.2206C43.0585 16.2609 43.0917 16.303 43.1259 16.3462C42.9801 15.9743 42.7575 15.5415 42.4889 15.2136C42.1292 14.7736 41.9583 14.3805 41.9787 14.0442C41.984 13.9437 42.0111 13.8455 42.0582 13.7562L42.2207 13.3602C42.1025 13.2438 41.9505 13.1669 41.7859 13.1402C41.5396 13.1085 41.2641 13.2212 40.9674 13.4747C40.9532 13.4865 40.9416 13.4984 40.931 13.5107L40.8946 13.5467C40.6296 13.7908 40.4937 14.0147 40.5112 14.1753C40.5152 14.2105 40.5264 14.2445 40.544 14.2753C40.622 14.2452 40.7048 14.2287 40.7885 14.2267C40.9244 14.2231 41.0593 14.2505 41.1828 14.3067V14.3067Z"
                fill="#234288"
              />
              <path
                d="M44.9565 16.173C44.7306 16.5866 44.1624 17.2336 44.0225 17.2854C43.8658 17.3434 43.3851 17.2559 43.6282 17.0115C43.7652 16.8736 43.7936 16.8811 43.8494 16.853C43.8928 16.8318 43.9525 16.789 44.0917 16.641C44.14 16.5883 44.1805 16.5293 44.2124 16.4657C44.4022 16.1002 44.3825 15.5768 44.3825 15.5768L44.533 15.1041L44.6788 14.9435C44.6788 14.9435 44.9889 15.3892 45.0432 15.7651C45.0592 15.854 45.0528 15.9455 45.0247 16.0315C45.0053 16.0801 44.9825 16.1274 44.9565 16.173Z"
                fill="#234288"
              />
              <path
                d="M43.5164 17.5828C43.4175 17.5413 43.3328 17.4726 43.2723 17.3848C43.1706 17.2307 43.1429 16.9852 43.3685 16.758C43.5044 16.6212 43.5824 16.5737 43.6782 16.5337C43.6848 16.5308 43.7198 16.5067 43.824 16.3948C43.849 16.3661 43.8702 16.3344 43.8871 16.3004C44.0066 16.07 44.0215 15.7003 44.0172 15.5887L44.015 15.5264L44.2081 14.9201L44.7183 14.3621L44.98 14.7386C45.0164 14.7912 45.34 15.2668 45.4034 15.714C45.4285 15.8618 45.416 16.0135 45.367 16.1553C45.3418 16.2195 45.3124 16.2819 45.2788 16.3422V16.3422C45.082 16.7022 44.4566 17.5079 44.152 17.6203C43.9895 17.6818 43.7256 17.6786 43.5164 17.5828Z"
                fill="#234288"
              />
              <path
                d="M44.5251 16.3567C43.9854 16.4261 43.586 16.6584 43.1167 15.7086C42.8776 15.2247 42.5759 14.4924 42.5059 14.3632L42.9698 13.3508L42.9643 13.3584L42.9439 13.2208L42.8601 12.6538L42.7963 12.5976L42.558 12.3874C42.4082 12.3589 42.2665 12.1487 42.2227 11.8765C42.1739 11.5673 42.2723 11.2954 42.4414 11.2692C42.6105 11.2429 42.789 11.4726 42.8379 11.7818C42.8531 11.8746 42.8542 11.9692 42.8411 12.0623C42.8776 12.1933 42.9527 12.3921 43.0893 12.4223C43.1249 12.4304 43.1598 12.4409 43.1939 12.4536C43.2307 12.3166 43.2379 12.1735 43.215 12.0335C43.1983 11.9255 43.1859 11.6094 43.1848 11.5892C43.2191 11.2893 43.3451 10.9527 43.7128 10.6694C44.0083 10.701 44.3066 10.6964 44.6009 10.6557C45.0452 10.5873 45.3327 10.4814 45.4887 10.323L45.5637 10.2949L45.6425 10.1394C45.7994 10.3765 45.9038 10.6437 45.9486 10.9235C45.9486 10.9286 45.9486 10.9333 45.9486 10.9379C45.9486 10.9426 45.9519 10.9531 45.9533 10.9613C45.9683 11.0571 45.9799 11.1709 45.9872 11.2969C46.0364 12.1084 46.1661 13.3613 45.7215 13.8044C45.6534 13.8725 45.3207 13.9438 45.3287 14.0295C45.3666 14.4096 44.5251 16.3567 44.5251 16.3567Z"
                fill="#FAC5C9"
              />
              <path
                d="M37.8643 25.5033C38.3132 25.939 40.9189 25.3687 43.015 24.8171C43.2756 24.9475 43.7468 21.6543 44.3634 21.8685C43.9213 23.2078 44.9202 24.0236 43.0023 28.4883C42.565 28.5419 38.8683 30.854 38.3821 30.7132C37.9084 30.5757 34.2335 29.5644 33.6559 29.8985C33.7168 29.3851 32.5153 28.884 35.0972 24.2163C35.4161 23.6359 37.5472 25.1973 37.8643 25.5033Z"
                fill="#2968B2"
              />
              <path
                d="M33.889 30.4075L33.3453 31.2453C33.2262 31.4365 33.8832 32.4694 36.9895 33.5523C40.3455 34.7217 41.8903 30.1116 42.0594 30.0659C42.2285 30.0201 42.3874 29.4095 42.3874 29.4095L33.889 30.4075Z"
                fill="#FFFBFA"
              />
              <path
                d="M38.1924 29.261C38.6781 29.4017 42.6197 28.1557 42.9634 28.4268C43.1182 28.5485 42.9885 29.6559 42.1656 29.6753C41.8165 29.6836 39.0316 29.945 38.3392 30.4339C37.1151 31.298 34.8255 31.2141 34.3306 31.0398C33.6017 30.7849 33.3765 30.3086 33.5988 29.9482C33.6128 29.9275 33.6319 29.9105 33.6542 29.8989C34.2311 29.5648 37.7186 29.1234 38.1924 29.261Z"
                fill="#234288"
              />
              <path
                d="M41.049 20.5325C41.0432 20.53 41.0379 20.5266 41.0333 20.5224C41.0274 20.5171 41.0226 20.5107 41.0192 20.5036C41.0158 20.4965 41.0139 20.4888 41.0135 20.4809C41.0131 20.473 41.0143 20.4652 41.017 20.4578C41.0197 20.4504 41.0239 20.4436 41.0293 20.4378C42.159 19.2079 42.6422 18.2251 42.8514 17.617C43.0762 16.9596 43.0336 16.5877 43.0336 16.5841C43.0316 16.5683 43.0361 16.5524 43.0459 16.5399C43.0558 16.5274 43.0703 16.5193 43.0863 16.5173C43.1022 16.5153 43.1183 16.5197 43.131 16.5295C43.1436 16.5392 43.1519 16.5536 43.1539 16.5693C43.1619 16.6316 43.3211 18.1196 41.1204 20.5184C41.1116 20.5281 41.0999 20.5347 41.0871 20.5373C41.0742 20.5398 41.0608 20.5381 41.049 20.5325V20.5325Z"
                fill="#FFFBFA"
              />
              <path
                d="M42.8892 21.9391C42.8812 21.9355 42.8741 21.9302 42.8683 21.9235C42.8626 21.9169 42.8584 21.909 42.8561 21.9006C42.5503 20.8141 42.9483 19.5605 43.336 18.7025C43.5918 18.1373 43.8997 17.5965 44.2558 17.0871C44.2655 17.0742 44.28 17.0656 44.296 17.0633C44.3121 17.0609 44.3284 17.065 44.3415 17.0745C44.3545 17.0841 44.3632 17.0983 44.3656 17.1142C44.368 17.1301 44.3639 17.1462 44.3542 17.1591C44.3349 17.1858 42.4049 19.8517 42.9731 21.8704C42.9765 21.8815 42.9766 21.8933 42.9733 21.9045C42.9701 21.9156 42.9636 21.9256 42.9548 21.9332C42.9459 21.9408 42.935 21.9457 42.9234 21.9474C42.9118 21.949 42.8999 21.9473 42.8892 21.9424V21.9391Z"
                fill="#FFFBFA"
              />
              <path
                d="M45.4512 12.5335H45.4563C45.5765 12.5021 45.691 12.4523 45.7956 12.3859C45.9323 12.2948 45.7799 12.0097 45.5493 11.6162C45.4964 11.5258 45.4509 11.448 45.4297 11.4001C45.4271 11.3944 45.4234 11.3892 45.4187 11.3849C45.4141 11.3806 45.4087 11.3772 45.4027 11.375C45.3968 11.3727 45.3904 11.3717 45.384 11.3718C45.3777 11.372 45.3714 11.3734 45.3656 11.376C45.3598 11.3786 45.3545 11.3823 45.3501 11.3869C45.3458 11.3914 45.3424 11.3968 45.3401 11.4027C45.3378 11.4086 45.3367 11.4149 45.3369 11.4212C45.3371 11.4274 45.3386 11.4336 45.3412 11.4394C45.3641 11.4902 45.4111 11.5701 45.4654 11.663C45.5483 11.7988 45.6241 11.9388 45.6925 12.0824C45.7788 12.2768 45.7417 12.3045 45.7413 12.3049C45.6462 12.3649 45.5424 12.4101 45.4334 12.4388C45.4271 12.44 45.4211 12.4423 45.4158 12.4458C45.4104 12.4492 45.4058 12.4537 45.4022 12.4589C45.3986 12.4641 45.3961 12.4699 45.3948 12.4761C45.3935 12.4822 45.3934 12.4886 45.3946 12.4948C45.3957 12.501 45.3981 12.5069 45.4016 12.5122C45.4051 12.5175 45.4096 12.522 45.4148 12.5256C45.4201 12.5291 45.426 12.5316 45.4323 12.5329C45.4385 12.5342 45.4449 12.5343 45.4512 12.5331V12.5335Z"
                fill="#EEA2AB"
              />
              <path
                d="M44.7698 11.0219C44.8259 11.0816 43.7545 11.4103 43.7392 11.293C43.7239 11.1756 44.6528 10.8977 44.7698 11.0219Z"
                fill="#4770B7"
              />
              <path
                d="M45.5262 10.8058C45.4628 10.9422 46.2303 11.1132 46.2226 11.0545C46.2149 10.9959 45.6045 10.6366 45.5262 10.8058Z"
                fill="#4770B7"
              />
              <path
                d="M44.5271 11.4704C44.5657 11.55 44.4564 11.6983 44.3864 11.6277C44.3164 11.5572 44.472 11.3581 44.5271 11.4704Z"
                fill="#4770B7"
              />
              <path
                d="M45.9199 11.3515C45.9586 11.4311 45.8492 11.5794 45.7792 11.5088C45.7093 11.4383 45.8649 11.2392 45.9199 11.3515Z"
                fill="#4770B7"
              />
              <path
                d="M37.5778 2.64832C37.6143 2.3801 37.9547 1.70614 37.8027 1.40948C37.6507 1.11282 37.412 0.691952 37.412 0.691952C37.412 0.691952 37.3027 1.24171 37.353 1.32127C37.4033 1.40084 36.8924 0.942888 36.6405 0.801039C36.3887 0.659189 36.0575 0.624268 36.0575 0.624268C36.037 0.863562 36.0866 1.10361 36.2003 1.31587C36.3891 1.65754 36.9944 2.78549 36.9944 2.78549L37.5778 2.64832Z"
                fill="#FAC5C9"
              />
              <path
                d="M37.1773 18.9211C36.506 18.5351 35.5411 12.4288 35.5844 11.8833C35.6278 11.3379 36.7047 2.57204 36.7047 2.57204L37.8879 2.48779C37.8879 2.48779 38.9448 9.33544 39.1667 10.2063C39.3886 11.0772 39.9243 14.3891 39.5369 15.5307C39.1496 16.6724 37.9994 19.3938 37.1773 18.9211Z"
                fill="#234288"
              />
              <path
                d="M45.6237 12.7523C45.6237 12.7523 45.1029 12.8189 44.9294 12.7102C44.756 12.6015 44.5286 12.6832 44.6893 12.8981C44.85 13.1131 44.6302 13.4551 45.2155 13.2754C45.8008 13.0958 45.7086 12.7945 45.6237 12.7523Z"
                fill="#EEA2AB"
              />
              <path
                d="M40.647 19.7581L42.3306 21.8559L41.3029 21.6885L41.0486 22.545L39.9531 20.1426L40.647 19.7581Z"
                fill="#E6AA31"
              />
              <path
                d="M40.3583 19.7113C40.3583 19.7113 41.6269 21.3674 41.5788 21.3606C41.5307 21.3537 41.1487 21.2767 41.1487 21.2767L41.0995 21.6968L40.0427 19.8809L40.3583 19.7113Z"
                fill="#E6AA31"
              />
              <path
                d="M39.5403 19.8419L38.2 22.1684L39.1897 21.8483L39.5731 22.6566L40.2852 20.1173L39.5403 19.8419Z"
                fill="#E6AA31"
              />
              <path
                d="M39.8187 19.752C39.8187 19.752 38.8205 21.5809 38.8668 21.5658C38.9131 21.5506 39.2786 21.4181 39.2786 21.4181L39.3923 21.8261L40.1576 19.8722L39.8187 19.752Z"
                fill="#E6AA31"
              />
              <path
                d="M41.4112 20.2599C41.838 19.5252 41.5811 18.5879 40.8374 18.1663C40.0938 17.7447 39.145 17.9985 38.7183 18.7331C38.2915 19.4678 38.5484 20.4051 39.292 20.8267C40.0357 21.2483 40.9845 20.9945 41.4112 20.2599Z"
                fill="#E6AA31"
              />
              <path
                d="M41.0805 20.9069C41.7361 20.3611 41.8197 19.3935 41.2672 18.7458C40.7147 18.098 39.7354 18.0154 39.0797 18.5613C38.4241 19.1071 38.3405 20.0746 38.893 20.7224C39.4454 21.3701 40.4248 21.4527 41.0805 20.9069Z"
                fill="#FFD047"
              />
              <path
                d="M41.1857 19.6556C41.1681 19.431 41.0808 19.2173 40.9356 19.0437C40.7905 18.87 40.5947 18.7448 40.3748 18.6852C40.155 18.6256 39.9219 18.6345 39.7074 18.7107C39.4929 18.7869 39.3074 18.9266 39.1763 19.1108C39.3746 18.9221 39.6371 18.8131 39.9124 18.8052C40.1877 18.7974 40.4561 18.8912 40.6652 19.0683C40.8742 19.2455 41.0089 19.4933 41.043 19.7633C41.0771 20.0333 41.008 20.3062 40.8493 20.5286C40.9672 20.4167 41.0585 20.2803 41.1167 20.1294C41.1749 19.9784 41.1984 19.8166 41.1857 19.6556V19.6556Z"
                fill="#C3812A"
              />
              <path
                d="M39.0841 19.6998C39.0662 19.4748 39.1197 19.2499 39.2371 19.0564C39.2156 19.0737 39.1948 19.0924 39.1752 19.1108C39.0176 19.3333 38.9494 19.6057 38.9838 19.8751C39.0183 20.1445 39.1529 20.3917 39.3615 20.5685C39.5702 20.7453 39.8379 20.8391 40.1127 20.8317C40.3874 20.8243 40.6496 20.7163 40.8482 20.5285C40.8643 20.5062 40.8792 20.4832 40.8931 20.4598C40.7367 20.5874 40.5478 20.6701 40.347 20.6989C40.1463 20.7277 39.9414 20.7015 39.7547 20.623C39.568 20.5446 39.4068 20.417 39.2885 20.2542C39.1702 20.0914 39.0995 19.8996 39.0841 19.6998V19.6998Z"
                fill="white"
              />
              <path
                d="M39.8573 18.8091C39.6299 18.8262 39.4134 18.9126 39.2378 19.0565C39.1038 19.2778 39.0533 19.5389 39.0952 19.7935C39.1371 20.0481 39.2687 20.2799 39.4669 20.4479C39.665 20.6159 39.9169 20.7093 40.178 20.7116C40.439 20.7139 40.6926 20.625 40.8937 20.4606C40.9976 20.2884 41.0516 20.0914 41.0499 19.891C41.0482 19.6906 40.9907 19.4945 40.8838 19.3242C40.777 19.1538 40.6248 19.0157 40.4439 18.9251C40.263 18.8344 40.0603 18.7945 39.858 18.8099L39.8573 18.8091Z"
                fill="#FFEF9C"
              />
              <path
                d="M40.4807 20.0828L40.4953 20.2704L39.7916 20.3237L39.7771 20.1361L40.0147 20.1181L39.9669 19.5025C39.9669 19.4866 39.9647 19.4712 39.964 19.455C39.9633 19.4388 39.964 19.4229 39.964 19.4067L39.8321 19.5331C39.8219 19.5427 39.8093 19.5495 39.7956 19.5529C39.7837 19.5559 39.7714 19.5567 39.7592 19.5554C39.7489 19.5545 39.7388 19.5515 39.7297 19.5468C39.7222 19.543 39.7154 19.538 39.7096 19.532L39.6182 19.4294L39.9786 19.0809L40.1972 19.0647L40.2774 20.0983L40.4807 20.0828Z"
                fill="#C3812A"
              />
              <path
                d="M40.4556 20.112L40.4702 20.2996L39.7665 20.3529L39.7519 20.1649L39.9895 20.1473L39.9418 19.5317C39.9418 19.5158 39.9396 19.5 39.9389 19.4841C39.9381 19.4683 39.9389 19.4521 39.9389 19.4359L39.8069 19.5619C39.7967 19.5716 39.7842 19.5785 39.7705 19.5821C39.7593 19.5848 39.7477 19.5857 39.7362 19.5846C39.7259 19.5837 39.7159 19.5807 39.7067 19.5759C39.6991 19.5723 39.6922 19.5672 39.6867 19.5608L39.5952 19.4582L39.9553 19.1101L40.1739 19.0935L40.2541 20.1271L40.4556 20.112Z"
                fill="#FFD047"
              />
            </g>
            <defs>
              <clipPath id="clip0_100_3363">
                <rect width="97" height="89" fill="white" transform="translate(0.499756)" />
              </clipPath>
            </defs>
          </svg>
        </motion.div>

        <Box
          component={motion.div}
          animate={{
            scale: [1.2, 1, 1, 1.2, 1.2],
            rotate: [270, 0, 0, 270, 270],
            opacity: [0.25, 1, 1, 1, 0.25],
            borderRadius: ['25%', '25%', '50%', '50%', '25%']
          }}
          transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
          sx={{
            width: 100,
            height: 100,
            borderRadius: '25%',
            position: 'absolute',
            border: (theme) => `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`
          }}
        />

        <Box
          component={motion.div}
          animate={{
            scale: [1, 1.2, 1.2, 1, 1],
            rotate: [0, 270, 270, 0, 0],
            opacity: [1, 0.25, 0.25, 0.25, 1],
            borderRadius: ['25%', '25%', '50%', '50%', '25%']
          }}
          transition={{
            ease: 'linear',
            duration: 3.2,
            repeat: Infinity
          }}
          sx={{
            width: 120,
            height: 120,
            borderRadius: '25%',
            position: 'absolute',
            border: (theme) => `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`
          }}
        />
      </RootStyle>
    </>
  );
}
