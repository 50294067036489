import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { ContributorPagination, ContributorState } from '../../@types/contributor';

// ----------------------------------------------------------------------

const initialState: ContributorState = {
  isLoading: false,
  error: false,
  contributors: {
    items: [],
    links: {},
    meta: {
      currentPage: 1,
      itemCount: 0,
      itemsPerPage: 10,
      totalItems: 0,
      totalPages: 1
    }
  }
};

const slice = createSlice({
  name: 'contributor',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getContributorsSuccess(state, action) {
      state.isLoading = false;
      state.contributors = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getContributors(contributorPagination?: ContributorPagination) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const query = `limit=${contributorPagination?.limit || 10}&page=${
        contributorPagination?.page || 1
      }`;
      const response = await axios.get(`user/get-contributors?${query}`);
      dispatch(slice.actions.getContributorsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
