import { createSlice } from '@reduxjs/toolkit';
import { IdeaReviewState } from '@types';
import axios from 'utils/axios';
import { dispatch } from '../store';

type Pagination = {
  page: number;
  limit: number;
};

const _initalState: IdeaReviewState = {
  isLoading: false,
  error: false,
  items: [],
  meta: {
    currentPage: 1,
    itemCount: 0,
    itemsPerPage: 10,
    totalItems: 0,
    totalPages: 1
  },
  generalScore: undefined,
  links: {}
};

const slice = createSlice({
  name: 'ideaReview',
  initialState: _initalState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getJuryReviews(state, action) {
      state.isLoading = false;
      state.items = action.payload.items;
      state.meta = action.payload.meta;
      state.links = action.payload.links;
    },
    getGeneralScore(state, action) {
      state.isLoading = false;
      state.generalScore = action.payload;
    }
  }
});

export function getJuryReviewsRequest({ id, pagination }: { id: string; pagination?: Pagination }) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());
      const query = `limit=${pagination?.limit || 10}&page=${pagination?.page || 1}`;
      const { data } = await axios.get(`/idea/view/jury/${id}?${query}`);
      dispatch(slice.actions.getJuryReviews(data));
    } catch (e) {
      dispatch(slice.actions.hasError(e));
      return;
    }
  };
}
export function getGeneralScoreRequest({ id }: { id: string }) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());
      const { data } = await axios.get(`/idea/view/score/${id}`);
      dispatch(slice.actions.getGeneralScore(data));
    } catch (e) {
      dispatch(slice.actions.hasError(e));
      return;
    }
  };
}

export default slice.reducer;
