import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import axios from 'utils/axios';
import { isValidToken, setSession } from 'utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType, UserIdeaDto } from '@types';

// ----------------------------------------------------------------------
enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
  resetPassword = 'RESET_PASSWORD',
  ConfirmResetPassword = 'CONFIRM_RESET_PASSWORD',
  resendActivationMail = 'RESEND_ACTIVATION_MAIL',
  ChangePassword = 'CHANGE_PASSWORD',
  UpdateProfile = 'UPDATE_PROFILE',
  GetUserIdeas = 'GET_IDEAS'
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
  [Types.resetPassword]: {};
  [Types.ConfirmResetPassword]: {};
  [Types.resendActivationMail]: {};
  [Types.ChangePassword]: {};
  [Types.UpdateProfile]: {};
  [Types.GetUserIdeas]: {
    userIdeaDto: UserIdeaDto;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  userIdeaDto: { items: [], links: null, meta: null }
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        userIdeaDto: { items: [], links: null, meta: null }
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        userIdeaDto: { items: [], links: null, meta: null }
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        userIdeaDto: { items: [], links: null, meta: null }
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: false,
        user: action.payload.user,
        ideaDto: null
      };
    case 'RESET_PASSWORD':
      return {
        ...state
      };
    case 'CONFIRM_RESET_PASSWORD':
      return {
        ...state
      };
    case 'RESEND_ACTIVATION_MAIL':
      return {
        ...state
      };
    case 'CHANGE_PASSWORD':
      return {
        ...state
      };
    case 'UPDATE_PROFILE':
      return {
        ...state
      };
    case 'GET_IDEAS':
      return {
        ...state,
        userIdeaDto: action.payload.userIdeaDto
      };
    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.post('/user');
          const user = response.data;

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const initialize = async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios.post('/user');
        const user = response.data;

        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: true,
            user
          }
        });
      } else {
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  };

  const login = async (email: string, password: string) => {
    const response = await axios.post('/user/login', {
      email,
      password
    });
    console.log(`login response => ${response}`);
    const { accessToken, user } = response.data;

    setSession(accessToken);
    dispatch({
      type: Types.Login,
      payload: {
        user
      }
    });
  };

  const register = async (
    email: string,
    password: string,
    fullName: string,
    phoneNumber: string
  ) => {
    const response = await axios.post('/user/register', {
      email,
      password,
      phone: phoneNumber,
      full_name: fullName
    });
    const { user } = response.data;

    dispatch({
      type: Types.Register,
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    setSession(null);
    window.localStorage.removeItem('user');
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    dispatch({ type: Types.Logout });
  };

  const resetPassword = async (email: string): Promise<void> => {
    return axios.post('/user/password/reset-mail', { email: email });
  };

  const confirmResetPassword = async (token: string, password: string): Promise<void> => {
    return axios.post('/user/password/reset', { token: token, password: password });
  };

  const resendActivationMail = async (email: string): Promise<void> => {
    return axios.post('/user/activation/resend-mail', { email: email });
  };

  const changePassword = async (current_password: string, new_password: string): Promise<void> => {
    const payload = {
      current_password: current_password,
      new_password: new_password
    };
    return axios.post('/user/change_password', payload);
  };

  const updateProfile = async ({
    full_name,
    phone
  }: {
    full_name: string | undefined;
    phone: string | undefined;
  }): Promise<void> => {
    const payload = {
      full_name: full_name,
      phone: phone
    };
    const response = await axios.post('/user/profile', payload);
    if (!!response) await initialize();
  };

  const getUserIdeas = async ({ page = 1 }: { page?: number }): Promise<void> => {
    const { data } = await axios.get(`/idea/owner/get-ideas?page=${page}&limit=10`);
    dispatch({ type: Types.GetUserIdeas, payload: { userIdeaDto: data } });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        initialize,
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
        confirmResetPassword,
        resendActivationMail,
        changePassword,
        getUserIdeas
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
