// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// hooks
import useAuth from './hooks/useAuth';
// components
//import Settings from './core/components/settings';
import RtlLayout from 'core/components/RtlLayout';
import ScrollToTop from 'core/components/ScrollToTop';
import GoogleAnalytics from 'core/components/GoogleAnalytics';
import NotistackProvider from 'core/components/NotistackProvider';
import ThemePrimaryColor from 'core/components/ThemePrimaryColor';
import ThemeLocalization from 'core/components/ThemeLocalization';
import { BaseOptionChartStyle } from 'core/components/charts/BaseOptionChart';
import LoadingScreen, { ProgressBarStyle } from 'core/components/LoadingScreen';

// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized } = useAuth();

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <GlobalStyles />
              <ProgressBarStyle />
              <BaseOptionChartStyle />
              {/* <Settings /> */}
              <ScrollToTop />
              <GoogleAnalytics />
              {isInitialized ? <Router /> : <LoadingScreen />}
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
