// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Label from 'core/components/Label';
import SvgIconStyle from 'core/components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking')
};

const sidebarConfigAdmin = [
  {
    subheader: 'Genel',
    items: [
      {
        title: 'Panel',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },
      {
        title: 'Fikirler',
        path: PATH_DASHBOARD.general.applications,
        icon: ICONS.chat,
        children: [
          { title: 'Onaylanmış Fikirler', path: PATH_DASHBOARD.general.applicationsApproved },
          { title: 'Onayda Bekleyen Fikirler', path: PATH_DASHBOARD.general.applicationsWaiting },
          {
            title: 'Fikre Katılmak İsteyenler',
            path: PATH_DASHBOARD.general.applicationsContributer
          },
          { title: 'Reddedilen Fikirler', path: PATH_DASHBOARD.general.applicationsRejected }
        ]
      }
    ]
  }
];

const sidebarConfigJury = [
  {
    subheader: 'Genel',
    items: [
      {
        title: 'Fikirler',
        path: PATH_DASHBOARD.general.applications,
        icon: ICONS.chat,
        children: [
          { title: 'Atanmış Fikirler', path: PATH_DASHBOARD.general.app },
          { title: 'Değerlendirilmiş Fikirler', path: PATH_DASHBOARD.general.applicationsEvaluated }
        ]
      }
    ]
  }
];
const sidebarConfigurator = (isAdmin: boolean) =>
  isAdmin ? sidebarConfigAdmin : sidebarConfigJury;

export default sidebarConfigurator;
